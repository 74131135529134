'use client'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import Pagination from '@mui/material/Pagination'
import CompanyCardList from './components/CompanyCardList'
import { fetchCompanyFilterRequest } from 'store/actions/companies/fetchCompanyFilter'
import styles from './Companies.module.scss'
import { changeCompanyValueWithConfigure } from 'helpers/config/changeCompanyValue'
import SortFilter from './components/SortFilter'
import SearchCompany from './components/SearchCompany'
import FeaturedCompanied from './components/FeaturedCompanied'
import { useFirstRender } from 'helpers/useFirstRender'
import CompanyCardLoader from 'components/Loader/CompanyCard'
import Image from 'next/image'
import { CircularProgress } from 'app/components/MUIs'
import { fetchCompanyFilterService } from 'store/services/companies2/fetchCompanyFilter'
import CompaniesSearchBgMobile from './companies-search-bg-mobile.svg'
import { useRouter } from 'next/navigation'
import { isEqual } from 'lodash-es'
import { omit } from 'helpers/utilities'

const initSearchQueries = {
  size: 15,
  page: 1,
  from_index: 0,
  query: '',
  company_size_ids: '',
  financing_stage_ids: '',
  industry_ids: ''
}

interface IProps {
  lang: any
  langKey: string
  configs: any
  companyData: any
  searchParams: any
}

// 计算 from_index
const calcPagesFrom = ({ isEmpty, num, pageSize }) => {
  let from = 0
  if (isEmpty) {
    from = num >= 2 ? 16 + (num - 2) * pageSize : 0
  } else {
    from = (num - 1) * pageSize
  }
  return from
}

// 判断搜索条件是否为空, 排除(page , size , from_index)
const isEmptyQuery = (query: typeof initSearchQueries) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { size, page, from_index, ...rest } = query
  return Object.values(rest).filter(Boolean).length == 0
}

// 剔除url或者传参中的page参数，由from_index替代
const getFetchQueries = (query: typeof initSearchQueries) => {
  return omit(query, ['page']) || {}
}

function calculatePagination(totalResults: number, firstPageCount: number, otherPageCount: number) {
  if (totalResults <= 0 || firstPageCount <= 0 || otherPageCount <= 0) {
    return { totalPages: 0 }
  }
  const totalPages = Math.ceil((totalResults - firstPageCount) / otherPageCount) + 1
  return { totalPages }
}

const Companies = (props: IProps) => {
  const {
    lang: { companies },
    langKey,
    configs,
    searchParams
  } = props
  const dispatch = useDispatch()
  const [featuredCompanies, setFeaturedCompanies] = useState(null)
  const [featuredCompany, setFeaturedCompany] = useState(null)
  const [totalPage, setTotalPage] = useState(null)
  const [reset, setReset] = useState(true)
  const firstRender = useFirstRender()
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    size: 16,
    from_index: 0,
    ...searchParams
  })
  const clearSearchRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const router = useRouter()
  const [showLoginCard, setShowLoginCard] = useState(true)
  const push = (params, options?) => {
    const urlParams = new URLSearchParams(params)
    router.push(location.pathname + '?' + urlParams.toString(), options)
  }
  const { config } = configs
  useEffect(() => {
    dispatch(
      fetchCompanyFilterRequest(
        getFetchQueries({
          size: isMobile ? 15 : 16,
          page: 1,
          from_index: 0,
          ...searchParams
        })
      )
    )
    const { query, company_size_ids, financing_stage_ids, industry_ids } = searchParams || {}
    if (query || company_size_ids || financing_stage_ids || industry_ids) {
      setShowLoginCard(false)
    } else {
      setShowLoginCard(true)
    }
  }, [searchParams])
  const preQuery = useRef(searchQuery)
  useEffect(() => {
    const pre = { ...preQuery.current, page: null }
    const current = { ...searchQuery, page: null }
    preQuery.current = searchQuery
    const { page, company_size_ids, financing_stage_ids, industry_ids, query } = searchQuery
    if (page > 1 || company_size_ids || financing_stage_ids || industry_ids || query) {
      setReset(false)
    }
    if (isMobile || isEqual(pre, current)) {
      return
    }
    push(searchQuery, { scroll: false })
  }, [searchQuery])
  const fetchCompanyFilterResponse = useSelector(
    (store: any) => store.companies.fetchCompanyFilter.response
  )
  const featureBanners = config.feature_banners
  const isFeaturedCompaniesFetching = useSelector(
    (store: any) => store.companies.fetchCompanyFilter.fetching
  )
  useEffect(() => {
    if (fetchCompanyFilterResponse?.companies) {
      const { total_num } = fetchCompanyFilterResponse
      const isEmpty = isEmptyQuery(searchQuery)
      const firstPage = isEmpty ? 16 : 15
      const { totalPages } = calculatePagination(total_num, firstPage, 15)
      setTotalPage(totalPages)
      const companies = fetchCompanyFilterResponse.companies.map((item) => {
        changeCompanyValueWithConfigure(item, config)
        return item
      })
      if (!companies || (companies && !companies.length)) {
        setFeaturedCompanies([])
        return
      }
      if (reset) {
        setFeaturedCompany(companies[0])
        setFeaturedCompanies(companies.slice(1))
      } else {
        setFeaturedCompany([])

        setFeaturedCompanies(companies)
      }
      setLoading(false)
    }
  }, [fetchCompanyFilterResponse, reset])

  const handleKeywordSearch = (keyword) => {
    const words = keyword.trim()
    let newQueries = { ...searchQuery, query: words, page: 1, from_index: 0 }
    const isEmpty = isEmptyQuery(newQueries)
    const pageSize = newQueries.page == 1 && isEmpty ? 16 : 15
    newQueries = { ...newQueries, page: 1, size: pageSize }
    setReset(isEmpty)
    setSearchQuery(newQueries)
    if (isMobile) {
      getCompanies(newQueries)
    }
  }

  const getCompanies = async (newQueries) => {
    const result = await fetchCompanyFilterService(getFetchQueries(newQueries))
    const companies = result?.data?.data?.companies || []
    setTotalPage(result?.data?.data?.total_num)
    setFeaturedCompanies(companies)
  }

  const handlePaginationClick = (_event, val) => {
    const num = Number(val) || 1
    const isEmpty = isEmptyQuery(searchQuery)
    const pageSize = num == 1 && isEmpty ? 16 : 15
    const from_index = calcPagesFrom({ isEmpty, num, pageSize })
    const newQueries = { ...searchQuery, page: num, size: pageSize, from_index }
    setSearchQuery(newQueries)
    setReset(isEmpty && num === 1)
    window.scrollTo(0, 0)
  }

  const handleSortFilter = (query) => {
    if (firstRender) return
    let newQueries = { ...searchQuery, ...query, from_index: 0 }
    const isEmpty = isEmptyQuery(newQueries)
    const pageSize = newQueries.page == 1 && isEmpty ? 16 : 15
    newQueries = { ...newQueries, page: 1, size: pageSize }
    setReset(isEmpty)
    setSearchQuery(newQueries)
    if (isMobile) {
      getCompanies(newQueries)
    }
  }

  const handleResetFilter = () => {
    setSearchQuery(initSearchQueries)
    clearSearchRef && clearSearchRef.current()
  }

  const CompaniesLoader = () => {
    return (
      <div className={styles.companyList}>
        {[...Array(12)].map((_, i) => (
          <div className={styles.companyItem} key={i}>
            <CompanyCardLoader />
          </div>
        ))}
      </div>
    )
  }

  const noMore = useMemo(() => {
    return searchQuery.page === +totalPage
  }, [searchQuery.page, totalPage])

  const shouldLoadMore = async () => {
    if (noMore || loading) {
      return
    }
    try {
      setLoading(true)
      const page = Number(searchQuery.page) + 1
      const newQueries = { ...searchQuery, page: page, size: 15, from_index: (page - 1) * 15 }
      setSearchQuery(newQueries)
      const result = await fetchCompanyFilterService(getFetchQueries(newQueries))
      const companies = result?.data?.data?.companies || []
      setFeaturedCompanies((pre) => [...pre, ...companies])
    } finally {
      setLoading(false)
    }
  }

  const NoMore = () => {
    return noMore ? (
      <div style={{ color: '#86909c' }}>{props?.lang?.search.noMore}</div>
    ) : (
      <button className={styles.viewMore} onClick={shouldLoadMore}>
        {props?.lang?.search.viewMore}
      </button>
    )
  }
  console.log({ featuredCompanies })
  const searchBackground = isMobile
    ? CompaniesSearchBgMobile
    : `${process.env.S3_BUCKET_URL}/companies/companies-search-bg.svg`
  return (
    <>
      <div className={styles.container}>
        {/* search */}
        <div className={styles.searchContainer}>
          <Image fill alt='bg' style={{ objectFit: 'cover' }} src={searchBackground} />

          <div className={styles.searchCompany}>
            <SearchCompany
              transitions={companies.search}
              clearSearchRef={clearSearchRef}
              onKeywordSearch={handleKeywordSearch}
              defaultQuery={searchQuery?.query}
            />
            <SortFilter
              lang={props.lang}
              config={config}
              sortFilterFn={handleSortFilter}
              resetFilterFn={handleResetFilter}
              searchParams={searchParams}
            />
          </div>
        </div>
        <div
          className={styles.placeholderSpace}
          style={{
            height: searchQuery.page === 1 || isMobile ? 75 : 125,
            backgroundColor: searchQuery.page === 1 || isMobile ? '#ffffff' : '#F7F8FA'
          }}
        />

        {/* featured company */}

        {!isFeaturedCompaniesFetching && reset ? (
          <>
            <FeaturedCompanied
              featuredCompany={featuredCompany}
              langKey={langKey}
              config={config}
              featureBanners={featureBanners}
              lang={props.lang}
            />
          </>
        ) : null}

        {/* companies list */}
        <div className={styles.companiesWrapper}>
          <div className={styles.companies}>
            {/* fetching loading: true */}
            {isFeaturedCompaniesFetching && !isMobile && <CompaniesLoader />}
            {/* fetching loading: false */}
            {!isFeaturedCompaniesFetching && (
              <div>
                {/* company card title */}
                {reset ? (
                  <h2 className={styles.popularCompanyTitle}>{companies.popularCompany.title}</h2>
                ) : null}

                {/* company card list */}
                <CompanyCardList
                  companiesList={featuredCompanies}
                  isLoading={isFeaturedCompaniesFetching}
                  transitions={companies.popularCompany}
                  langKey={langKey}
                  config={config}
                  lang={props.lang}
                  page={searchQuery.page}
                  showLoginCard={showLoginCard}
                />
              </div>
            )}
            {/* Pagination */}
            {totalPage > 1 && (
              <div className={styles.companiesPagination}>
                <Pagination
                  page={+searchQuery.page}
                  count={totalPage || 1}
                  onChange={handlePaginationClick}
                  defaultPage={+searchQuery.page || 1}
                  shape='rounded'
                  color={'primary'}
                />
              </div>
            )}
            {/* mobile pagination */}

            {totalPage > featuredCompanies?.length && (
              <div className={styles.loadMore}>
                {!loading ? <NoMore /> : <CircularProgress color={'primary'} size={20} />}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Companies
