import React, { useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import OutlinedInput from '@mui/material/OutlinedInput'
import styled from '@emotion/styled'
import classNames from 'classnames'

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
interface MaterialSelectCheckMarksProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
  label?: string | React.ReactNode
  options: Array<OptionType>
  value: any
  onSelect?: any
  greyBg?: boolean
  fieldRef?: any
  error?: any
  optionCate?: string | undefined
}

interface OptionType {
  value?: any
  label?: string | boolean
  category?: string
  children?: Array<{ value: any; label: string;[key: string]: any }>
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      overflow: 'auto',
    }
  }
}
const regroupData = (
  data,
  { categoryKey = 'category_value', valueKey = 'value', labelKey = 'label' } = {}
) => {
  const result = [...new Set([...data].map((item) => item[categoryKey]))].map((item) => ({
    category: item,
    children: [] as any
  }))
  result.forEach((item) => {
    data.forEach((el) => {
      if (el[categoryKey] === item.category) {
        item.children.push({
          [valueKey]: el.value,
          [labelKey]: el.value,
          id: el.id
        } as any)
      }
    })
  })
  return result
}

const CategoryArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <path
      d="M6.46039 12.4592L5.54116 11.54L9.08154 7.99961L5.54116 4.45923L6.46039 3.53999L10.92 7.99961L6.46039 12.4592Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
)

const MultipleSelect = ({
  id,
  label,
  options,
  className,
  onSelect,
  style,
  value,
  fieldRef,
  error,
  optionCate
}: MaterialSelectCheckMarksProps) => {
  const [selectedOptions, setSelectedOptions] = useState<any>([])
  const [index, setIndex] = useState(0)

  useEffect(() => {
    setSelectedOptions(value)
  }, [value])
  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event
    // On autofill we get a the stringified value.
    const formattedValue = (typeof value === 'string' ? value.split(',') : value)
      .filter((item) => item !== 'emptyValue')
      .map((item) => item.toLowerCase())
    // setSelectedOptions(formattedValue)
    if (onSelect) {
      onSelect(formattedValue)
    }
  }

  const renderTab = () => {
    const tabData = regroupData(options)
    return tabData.map((option, idx) => (
      <div
        key={idx}
        className={classNames({
          selected: index === idx
        })}
        onClick={(e) => {
          e.stopPropagation()
          setIndex(idx)
        }}
      >
        <span>{option.category}</span>
        <CategoryArrow />
      </div>
    ))
  }

  const renderContent = () => {
    const tabData = regroupData(options)
    const data = tabData
      .map((item, index) =>
        item.children.map((item) => ({
          ...item,
          index
        }))
      )
      .flat()
    return data.length
      ? data.map(({ ...option }) => {
        return <MenuItem
          key={option.value}
          value={option.value}
          style={{
            display: index === option.index ? '' : 'none'
          }}
          onClick={(e) => {
            e.stopPropagation()
            let newOptions = []
            if (!selectedOptions.includes(option.value)) {
              newOptions = [...selectedOptions, option.value]
            } else {
              newOptions = selectedOptions.filter((item) => item !== option.value)
            }
            setSelectedOptions(newOptions)
            handleChange({
              target: {
                value: option.value
              }
            } as any)

            if (onSelect) {
              onSelect(newOptions)
            }
          }}
        >
          <Checkbox
            checked={selectedOptions.includes(option.value)}
            size='small'
            onChange={(e) => {
              e.stopPropagation()
            }}
          />
          <ListItemText primary={option.label} />
        </MenuItem>
      })
      : null
  }

  const renderIndustryData = () => {
    return (
      <IndustryDataWrapped>
        <div className='tab'>{renderTab()}</div>
        <div className='content'>{renderContent()}</div>
      </IndustryDataWrapped>
    )
  }

  return (
    <FormControl fullWidth className={className} size='small'>
      <InputLabel id={`${id}-select-label`}>{label}</InputLabel>
      <Select
        {...fieldRef}
        variant='filled'
        error={error}
        labelId={`${id}-select-label`}
        id={id}
        multiple
        IconComponent={(props) => (
          <ExpandMoreOutlinedIcon
            {...props}
            fontSize={'small'}
            strokeWidth={0.3}
          ></ExpandMoreOutlinedIcon>
        )}
        style={{ ...style, background: value?.length ? '#E7F1FB' : '#F0F0F0' }}
        value={selectedOptions.length ? selectedOptions : ['emptyValue']}
        label={label}
        onChange={handleChange}
        input={<OutlinedInput label='Tag' />}
        placeholder={label}
        MenuProps={MenuProps}
        renderValue={(selected: any) => {
          if (selected?.[0] === 'emptyValue') {
            return (
              <div
                style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  position: 'relative',
                  top: 2,
                  fontSize: 16
                }}
              >
                {label}
              </div>
            )
          } else {
            return (
              <div
                style={{
                  position: 'relative',
                  fontSize: 16,
                  top: 2
                }}
              >{`${label} ${selectedOptions?.length ? `(${selectedOptions.length})` : label}`}</div>
            )
          }
        }}
      >
        {options?.length
          ? optionCate === 'industry'
            ? renderIndustryData()
            : options.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  checked={selectedOptions.indexOf(option.value?.toLowerCase()) > -1}
                  size='small'
                />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  )
}

const IndustryDataWrapped = styled.div`
  display: flex;
  width: 700px;
  max-height: 382px;
  overflow: hidden;
  @media screen and (max-width: 576px) {
    max-width: 100%;
    width: 100vw
  }
  .tab {
    width: 50%;
    padding: 0 6px;
    white-space: pre-wrap;
    box-sizing: border-box;
    overflow-y: auto;
    border-right: 1px solid #e7e7e7;
    > div {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      border-radius: 3px;
      padding: 6px 8px;
      box-sizing: border-box;
      min-height: 40px;
      font-size: 14px;
      > span {
        flex: 1;
      }
      &:hover{
        background: #f5f7fb;
      }
    }
    > .selected {
      background: #f5f7fb;
    }
  }
  .content {
    width: 50%;
    padding: 0 6px;
    box-sizing: border-box;
    overflow-y: auto;
    & .MuiMenuItem-root {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      min-height: 40px;
      border-radius: 3px;
      word-wrap: break-word;
      word-break: break-word;
      &:hover {
        background: #f5f7fb;
      }
      & .MuiCheckbox-root {
        padding: 0;
        margin-right: 10px;
      }
      & .MuiTypography-root {
        line-height: 1;
        font-size: 14px;
      }
    }

    & .MuiListItemText-root {
      white-space: pre-wrap;
    }
  }
`

export default MultipleSelect
